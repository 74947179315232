::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:goldenrod ; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}